<template>
	<v-content class="landingBackground ma-0 pa-0" fluid>
		<v-toolbar v-if="!hideAppBar" flat color="signzy_header_color" id="appToolBar">
			<v-toolbar-title>
				<img style="margin-left: 10px" :src="imageLogo" height="30px;" />
			</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>
		<v-row justify="center" class="ma-0 pa-0">
			<v-col cols="12" sm="12" md="10" lg="8" xl="6" v-if="isLoading">
				<div class="text-center">
					<p class="headline mt-3">Please wait..</p>
				</div>
			</v-col>
			<v-col cols="12" sm="12" md="10" lg="8" xl="6" v-if="!isLoading" class="ma-0 pa-0">
				<v-card class="vcipCard elevation-0">
					<!-- <v-card-title v-if="$route.name!='startVideo'" class="vcipTitle">{{processLabel}}</v-card-title> -->
					<v-card-text>
						<router-view :requestId="requestId" :callData="callData" :socket="socket" :worker="swRegistration"
							:isMultipleSession="isMultipleSession"></router-view>
					</v-card-text>
					<v-card-actions v-if="!hideBranding && $route.name != 'startVideo'" class="float-right">
						<img style="width: 150px" src="@/assets/signzy-logo-secured.png" />
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="noSupport" persistent max-width="550">
			<v-card>
				<v-card-text class="text-center">
					<p class="title pt-4">Sorry, currently we dont have support for your device!</p>
					<v-spacer></v-spacer>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-content>
</template>

<script>
import { getBrowserData } from "@/Plugins/videoProcess";
import { padDoubleDigits, getRandomInt } from "@/Plugins/utils.js";
import { end_points, vcip_end_points } from "./../../config";
import DetectRTC from "detectrtc";
import DeviceDetector from "device-detector-js";
import { isBlackListedDevice } from "@/flux/deviceCheck.js";
import io from "socket.io-client";
import axios from "axios";
import { getInitiationId, logReportData } from "@/Plugins/reportLogger.js";
import { endCustomer, reAgent } from "@/assets/subStatus.json";
import CallState from "../../enums/CallStates"

export default {
	data: () => ({
		socket: undefined,
		requestId: "",
		isLoading: true,
		noSupport: false,
		hideAppBar: false,
		checkbox: false,
		IOSbrowserComp: 11,
		firefoxBrowserComp: 78,
		chromeBrowserComp: 80,
		edgeBrowserComp: 90,
		imageLogo: "",
		processLabel: "Video KYC",
		hideBranding: false,
		expiredDocument: {},
		callData: {
			configuration: {}
		},
		sessionCheckDone: false,
		metaData: {},
		expiredDocument: {},
		isMultipleSession: false,
		FrontDebugLabel: "landingPage",
		initiationId: null,
		swRegistration: null,
		incompatibleFlag: false,
		incompatibleReason: "",
		enableChime: false,
		globalConfigurations: ["enableChime"],
		logObject: { endCustomer, reAgent },
		deviceCompatible:true,
		browserCompatible:true,
	}),
	methods: {
		isLoadedInIframe() {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
		checkForExpiry(documents) {
			for (let i = 0; i < documents.length; i++) {
				if (documents[i].documentExpiry) {
					if (documents[i].documentExpiry < Date.now()) {
						this.expiredDocument = documents[i];
						return true;
					}
				}
			}
			return false;
		},
		checkForBrowserCompatibility(browserData) {
			//logic for Browser compatibiltiy
			if (browserData.deviceInfo.is_mobile) {
				if (browserData.userAgent.includes("iPhone") || browserData.userAgent.includes("iPad")) {
					if (!browserData.browserName.includes("Safari") && !browserData.browserName.includes("Chrome")) {
						if (!this.enableChime) {
							this.incompatibleFlag = true;
							this.incompatibleReason = "IOSBrowserIncompatible";
							this.browserCompatible = false;
							sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
							this.$router.replace({
								path: "/error/IOSBrowserIncompatible"
							});
						}
					} else if (
						(browserData.browserName.includes("Chrome") && parseInt(browserData.browserVersion) < this.chromeBrowserComp) ||
						(browserData.browserName.includes("Safari") && parseInt(browserData.browserVersion) < this.IOSbrowserComp)
					) {
						this.incompatibleFlag = true;
						this.incompatibleReason = "IOSBrowserVersionIncompatible";
						this.browserCompatible = false;
						sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
						this.$router.replace({
							path: "/error/IOSBrowserVersionIncompatible"
						});
					}
				} else {
					if (!(browserData.browserName.includes("Chrome") || browserData.browserName.includes("Firefox"))) {
						this.incompatibleFlag = true;
						this.incompatibleReason = "mobileBrowserIncompatible";
						this.browserCompatible = false;
						sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
						this.$router.replace({
							path: "/error/mobileBrowserIncompatible"
						});
					} else if (browserData.browserName.includes("Chrome") && parseInt(browserData.browserVersion) < this.chromeBrowserComp) {
						this.incompatibleFlag = true;
						this.browserCompatible = false;
						this.incompatibleReason = "mobileBrowserVersionIncompatible";
						sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
						this.$router.replace({
							path: "/error/mobileBrowserVersionIncompatible"
						});
					}
				}
			} else {
				if (browserData.userAgent.includes("Macintosh")) {
					if (!browserData.browserName.includes("Safari") && !browserData.browserName.includes("Chrome") && !browserData.browserName.includes("Firefox")) {
						this.incompatibleFlag = true;
						this.incompatibleReason = "macBrowserIncompatible";
						this.browserCompatible = false;
						sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
						this.$router.replace({
							path: "/error/macBrowserIncompatible"
						});
					} else if (
						(browserData.browserName.includes("Firefox") && parseInt(browserData.browserVersion) < this.firefoxBrowserComp) ||
						(browserData.browserName.includes("Chrome") && parseInt(browserData.browserVersion) < this.chromeBrowserComp) ||
						(browserData.browserName.includes("Safari") && parseInt(browserData.browserVersion) < this.IOSbrowserComp)
					) {
						this.incompatibleFlag = true;
						this.incompatibleReason = "macBrowserVersionIncompatible";
						this.browserCompatible = false;
						sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
						this.$router.replace({
							path: "/error/macBrowserVersionIncompatible"
						});
					}
				} else {
					if (!browserData.browserName.includes("Chrome") && !browserData.browserName.includes("Firefox") && !browserData.browserName.includes("Microsoft Edge")) {
						this.incompatibleFlag = true;
						this.incompatibleReason = "browserIncompatible";
						this.browserCompatible = false;
						sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
						this.$router.replace({
							path: "/error/browserIncompatible"
						});
					} else if (
						(browserData.browserName.includes("Firefox") && parseInt(browserData.browserVersion) < this.firefoxBrowserComp) ||
						(browserData.browserName.includes("Chrome") && parseInt(browserData.browserVersion) < this.chromeBrowserComp) ||
						(browserData.browserName.includes("Microsoft Edge") && parseInt(browserData.browserVersion) < this.edgeBrowserComp)
					) {
						this.incompatibleFlag = true;
						this.incompatibleReason = "browserVersionIncompatible";
						this.browserCompatible = false;
						sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
						this.$router.replace({
							path: "/error/browserVersionIncompatible"
						});
					}
				}
			}
		},
		async setGlobalConfigurations(globalConfigs) {
			try {
				if (globalConfigs) {
					for (let i = 0; i < this.globalConfigurations.length; i++) {
						let configName = this.globalConfigurations[i];
						this[configName] = globalConfigs[configName] ? globalConfigs[configName] : this[configName];
					}
					console.log("this is the configuration that is set", this);
				}
			} catch (err) {
				console.error("Error setting global configurations", err);
			}
		},
		getRequestIdAndToken(tknString) {
			try {
				const { requestId, token } = JSON.parse(window.atob(tknString));
				return {
					requestId,
					token
				};
			} catch (error) {
				console.log(error);
				return {
					requestId: tknString,
					token: undefined
				};
			}
		},
		async registerServiceWorker() {
			try {
				// Registering service worker
				// to send push notifications in case of mobile devices
				// NOTE: service workers public patch is needed in order to register it
				// hence using public path stored in env to access it.
				const swRegistration = await navigator.serviceWorker.register(`${window.location.origin}/service-worker.js`);
				return swRegistration;
			} catch (err) {
				console.log("Error in registering service worker", err);
			}
		},
		async requestNotificationPermission() {
			try {
				const permission = await window.Notification.requestPermission();
				// value of permission can be 'granted', 'default', 'denied'
				if (permission !== "granted") {
					console.log("Permission not granted");
				}
			} catch (err) {
				console.log("Error in notification permissions", err);
			}
		}
	},

	async created() {
		if (parseInt(sessionStorage.getItem("vpnChecker")) > 0 ) {
			this.$router.replace({ path: '/vpndetected' }); 
			return;
		}
		//setting callAgainHelper once
		if (!sessionStorage.getItem("callAgainHelper")) {
			sessionStorage.setItem("callAgainHelper", this.$router.currentRoute.path);
		}
		const deviceDetector = new DeviceDetector();
		const userAgent = navigator.userAgent;
		if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			const device = deviceDetector.parse(userAgent);
			let deviceDetails = {
				complete_device_name : `${device?.device?.brand ?? ''} ${device?.device?.model ?? ''}`,
				form_factor: device?.device?.type,
				os: device?.os
			};
			console.log(deviceDetails);
			if (!(deviceDetails.complete_device_name.replace(/\s/g, "").length < 1)) {
				try {
					// let isBlackList = (await axiosInstance.post(vcip_end_points.device_compatibility_check, {
					//     ...deviceDetails
					// })).data.blacklisted;
					// Checking device compatibility on front end only
					let isBlackList = await isBlackListedDevice(deviceDetails);
					if (isBlackList) {
						this.incompatibleFlag = true;
						this.incompatibleReason = "deviceIncompatible";
						this.deviceCompatible = false;
						this.$router.replace({
							path: "/error/notCompatible"
						});
					}
				} catch (err) {
					console.log("Device check could not be completed:::::::", err);
				}
			} else {
				console.log("Device name is an empty string ::::::: skipping device check.");
			}
		}
		if (!("serviceWorker" in navigator)) {
			console.log("worker not supported");
		} else {
			if (!("PushManager" in window)) {
				console.log("Push manager not supported");
			} else {
				const permission = await this.requestNotificationPermission();
				this.swRegistration = await this.registerServiceWorker();
			}
		}
		let selfIns = this;
		this.$store.commit("setEndUserData", ["isPresenter", "true"]);
		try {
			const { requestId, token } = this.getRequestIdAndToken(this.$route.params.requestId);
			this.requestId = requestId;
			sessionStorage.setItem("requestId", requestId);
			sessionStorage.setItem("token", token);
			console.log("User's Request Id:::::", this.requestId);
			let verifyCall = undefined;

			//sending device data to the call
			let browserData = await getBrowserData();
			await axiosInstance
				.post(end_points.update_device_data(this.requestId), {
					browserData: browserData
				})
				.catch((err) => {
					console.log(`Error while pushing browserData to call ${err}`);
				});

			if (!this.isLoadedInIframe && sessionStorage.requestId) {
				this.requestId = sessionStorage.requestId;
			} else if (token) {
				verifyCall = await axiosInstance.post(end_points.verify_call, {
					requestId,
					token
				});
				if (!this.isLoadedInIframe) {
					sessionStorage.requestId = requestId;
				}
			}
			if (!verifyCall || (verifyCall.data && verifyCall.data.status == "success")) {
				this.$store.commit("setEndUserData", ["requestId", this.requestId]);
				
				try {
					this.initiationId = verifyCall.data.initiationId;
					sessionStorage.setItem("initiationId", this.initiationId);
					!sessionStorage.getItem("oldInitiationId") && sessionStorage.setItem("oldInitiationId", this.initiationId);
				} catch(error){
					console.error("Unable to set initiationId: ", error);
				}

				let res = await axiosInstance.get(end_points.polling_api(this.requestId));
				let callTimestampData = res.data.callTimestampData;
				this.callData = res.data.input.callData;
				this.callData["customerId"] = res.data.customerId;
				this.metaData = res.data.input.metaData;
				this.socket = io("/", { query: `role=endUser&requestId=${this.requestId}` });

				this.socket.on("error", (data) => {
					console.log(`error type :: ${data.type} ::: ${data.reason}`);
				});

				this.socket.on("connect", (data) => {
					console.log("socket connected");
					if (!this.sessionCheckDone) {
						this.socket.emit("roleEndUser", {
							requestId: this.requestId
						});
						this.sessionCheckDone = true;

						try {
							let data = {};
							if (callTimestampData && callTimestampData.callEnded) {
								data = { ...this.logObject.endCustomer.landingPage.linkExpired };
								data.currentProcess = "link expired";
								data.isCallActive = false;
								this.$router.replace({
									path: "/error/rejected"
								});
							}
							if (!DetectRTC.isWebRTCSupported || DetectRTC.isWebSocketsBlocked || this.incompatibleFlag) {
								eventBus.$off("landing-page-mis-triggered");
								data = { ...this.logObject.endCustomer.incompatible.browser };
								data.currentProcess = this.incompatibleReason ? this.incompatibleReason : "incompatible device";
							}
							//Commenting this code since it's never registered due to the socket communication delay
							// if(this.isMultipleSession){
							//   data.status = "Dropped"
							//   data.subStatus = "Dropped due to multiple sessions"
							//   data.currentProcess = "multiple sessions detected"
							// }
							data.deviceCompatible = this.deviceCompatible;
							data.browserCompatible = this.browserCompatible;
							data.browserData = browserData;
							data.onPage = "Landing";
							data.callId = this.requestId;
							if (!data.hasOwnProperty("currentProcess")) {
								data.currentProcess = "User was on landing page";
							}
							data.callState = CallState.CUSTOMER_DROP_OFF;
							this.$store.commit("setEndUserData", ["browserData", browserData]);
							logReportData(this.socket, this.initiationId, data);
						} catch (err) {
							console.log(`error type :: socket reporting :::`);
							console.log(err);
						}
					}
				});

				this.socket.on("disconnect", () => {
					this.socket.removeAllListeners();
					// can redirect to a server error page
				});

				this.socket.on("InstancePresentAlready", (data) => {
					this.isMultipleSession = true;
					this.$router.replace({
						path: "/error/multipleSession"
					});
				});

				if (this.callData?.configuration?.scheduledExpiry) {
					if (this.callData.configuration.scheduledExpiry !== "") {
						this.$store.commit("setScheduledCallExpiry", this.callData.configuration.scheduledExpiry);
					}
				}

				//This sets the theme for the End user
				if (this.callData?.configuration) {
					let callConfig = {};
					callConfig.textColor = this.callData.configuration?.textColor ?? false;
					callConfig.buttonTextColor = this.callData.configuration?.buttonTextColor ?? false;
					callConfig.buttonOutlineColor = this.callData.configuration?.buttonOutlineColor ?? false;
					this.$store.commit("setCallConfig", callConfig);
				}

				// check if the call is initiated for chime
				try {
					let globalConfigs = await axiosInstance.get(end_points.get_global_configs(res?.data?.customerId));
					await this.setGlobalConfigurations(globalConfigs?.data);

					// giving value from call config for initialization
					if (this.callData?.configuration?.enableChime) {
						this.enableChime = globalConfigs?.enableChime ?? this.callData?.configuration?.enableChime;
					}
				} catch (err) {
					console.error("Error fetching global config", err);
				}

				//Using sessionStorage since router guards often cannot access the store before initialization which will cause issues later down the line
				if (this.callData?.instructionPage?.disableInstructionsPage) {
					sessionStorage.setItem("disableInstructionsPage", true);
				}

				//check for browser compatibility
				this.checkForBrowserCompatibility(browserData);

				// getInitiationId(this.socket, this.requestId);

				if (res.data.directAssignment) {
					this.callData["directAssignment"] = res.data.directAssignment;
				}
				// this.isLoading = false;

				if (this.callData.configuration.instructionHeader) {
					this.processLabel = this.callData.configuration.instructionHeader;
				}

				if (this.callData.configuration.hideBranding) {
					this.hideBranding = this.callData.configuration.hideBranding;
				}

				if (this.callData.configuration.imageLogo) {
					this.imageLogo = "";
					if (this.callData.configuration.imageLogo.includes("base64")) {
						this.imageLogo = this.callData.configuration.imageLogo;
						localStorage.setItem("imageLogo", this.imageLogo);
					} else {
						axiosInstance
							.post(vcip_end_points.download_image, { q: this.callData.configuration.imageLogo, id: requestId }, { headers: { Authorization: token } })
							.then((respImg) => {
								if (respImg?.status === 200 && respImg?.data?.file) {
									this.imageLogo = `data:${respImg.headers["content-type"].split(";")[0]};base64, ${respImg.data.file}`;
								} else {
									this.imageLogo = this.callData.configuration.fallBackImagePath || require("@/assets/signzy.png");
								}
								localStorage.setItem("imageLogo", this.imageLogo);
							})
							.catch((err) => {
								console.error("Error while fetching image logo: ", err);
								this.imageLogo = this.callData.configuration.fallBackImagePath || require("@/assets/signzy.png");
								localStorage.setItem("imageLogo", this.imageLogo);
							});
					}
				} else {
					this.imageLogo = require("@/assets/signzy-logo-dark.png");
					localStorage.setItem("imageLogo", this.imageLogo);
				}

				this.hideAppBar = this.callData.configuration.hideAppBar || false;

				if (this.callData.configuration.allowUserToTurnOffCamera) {
					this.$store.commit("setEndUserData", ["isUserCameraToggle", true]);
				} else {
					this.$store.commit("setEndUserData", ["isUserCameraToggle", false]);
				}

				//Checking if the video is cussefully done
				if (callTimestampData && callTimestampData.callEnded) {
					sessionStorage.getItem("disableInstructionsPage") && sessionStorage.setItem("errorPage", true);
					this.$router.replace({
						path: "/error/expired"
					});
				}

				this.isLoading = false;

				if (this.callData.configuration.headerColor) {
					this.$vuetify.theme.themes.dark.signzy_header_color = this.callData.configuration.headerColor;
					this.$vuetify.theme.themes.light.signzy_header_color = this.callData.configuration.headerColor;
					//setting up color gradient
					document.getElementById("appToolBar").style.backgroundImage = `linear-gradient(${this.callData.configuration.headerColor || "#4a9ad9"}, ${this.callData.configuration.headerEndColor || "#1d4886"
						})`;
				}

				//Checking for documents
				if (this.callData.configuration.isDocumentExpiryValidate && this.callData.users[0] && this.callData.users[0].documents && this.checkForExpiry(this.callData.users[0].documents)) {
					if (this.callData.configuration.documentExpiryCallback) {
						await axios
							.post(vcip_end_points.send_cb, {
								url: this.callData.configuration.documentExpiryCallback,
								body: {
									eventFor: "documentExpiry",
									isExpired: true,
									document: this.expiredDocument
								}
							})
							.catch((err) => {
								console.log("Something went wrong in sending expiry calback", err);
							});
						//window.open(this.callData.configuration.documentExpiryCallback, "_self");
					}
					this.$router.replace({
						path: "/error/documentExpired"
					});
				}
				let callRescheduleFlag = false;
				this.metaData && this.metaData.scheduled ? (callRescheduleFlag = true) : (callRescheduleFlag = false);
				this.$store.commit("setEndUserData", ["isSchedule", callRescheduleFlag]);

				//Checking if the link is exprired for scheduled
				if (this.metaData && this.metaData.scheduled && this.callData.configuration.scheduledExpiry) {
					let offset = (new Date().getTimezoneOffset() / 60) * -1;
					let nowTime = Date.now() + offset * 3600000;
					// nowTime += this.callData.configuration.scheduledExpiry * 24 * 60 * 60 * 1000

					// Buffer value is set when the value is set to expiry value is set to 0, allowing user to join call for 15 mins from the given time
					let bufferValue = parseInt(this.callData.configuration.scheduledExpiry) ? parseInt(this.callData.configuration.scheduledExpiry) : 15 / 60;

					let scheduledTimeWithBuffer = this.metaData.scheduled.to + bufferValue * 60 * 60 * 1000 - 900000; // Writing a patch to substract the 15 min added from Reschedule component
					if (scheduledTimeWithBuffer < nowTime) {
						this.$router.replace({
							path: "/error/expired"
						});
					}
				}

				//Checking Browser Compatibility
				if (!DetectRTC.isWebRTCSupported || DetectRTC.isWebSocketsBlocked) {
					this.$router.replace({
						name: "incompatible"
					});
				}
			} else {
				this.$router.replace({
					path: "/error/expired"
				});
			}
		} catch (err) {
			console.log("Something went wrong", err);
			this.$router.replace({
				path: "/error/expired"
			});
		}
		eventBus.$on("customMIS", async (info) => {
			try {
				let initiationId = info.initiationId || sessionStorage.getItem("oldInitiationId");
				let data = { ...info };
				data.callId = info.callId || sessionStorage.getItem("oldRequestId");
				data.currentProcess = "Generating a new initiationId and instance for the user";
				logReportData(this.socket, initiationId, data);
			} catch (err) {
				console.log(err);
			}
		});
	},
	watch: {
		isMultipleSession(value) {
			if (value) {
				//Added a watcher to handle multiple session and firing customized event to handle the socket updation delay
				eventBus.$emit("multiple-session-detected", this.initiationId);
			}
		}
	}
};
</script>
<style scoped>
.landingBackground {
	background: #fcfdff;
}

.vcipCard {
	margin-top: 1vh;
	color: black !important;
}

.vcipTitle {
	background: #eff4fc;
	color: #2f75c7;
}
</style>
