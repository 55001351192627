<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">Global Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row style="margin-top:5px;">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox class="mt-0 mb-0" v-model="config.global.keepREOnline"
                  label="Do you want agents to automatically go online after the call?">
                </v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-select :items="['TRACE','DEBUG','INFO','WARN','ERROR','FATAL']" v-model="config.global.jitsiLogLevel" style="padding-top:0px;margin-top:0px;"
                    label="LOG LEVEL">
                </v-select>
              </v-col>
            </v-row>
            <v-row style="margin-top:5px;">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox class="mt-0 mb-0" v-model="config.global.enable2FAforAgents"
                  label="Enable 2FA for agent login">
                </v-checkbox>
              </v-col>
            </v-row>
            <!--settings for password and login-->
            <v-row style="margin-top:0px;">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-top:0px;">
                <v-checkbox v-model="config.global.isChangePasswordRequired" label="Do you want to set changing login password request periodically?">
                </v-checkbox>
                <v-text-field v-if="config.global.isChangePasswordRequired" label="Number of days after password reset should be requested"
                  v-model="config.global.changePasswordDuration" :rules="rules.loginPasswordFieldChecks" ref="password_reset_days">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-top:0px;">
                <v-checkbox v-model="config.global.isloginAttemptLimited" label="Do you want to limit number of login attempts?">
                </v-checkbox>
                <v-text-field v-if="config.global.isloginAttemptLimited" label="Number of login attempts for successful login"
                  :rules="rules.loginAttemptsCheck" v-model="config.global.allowedLoginAttempts" ref="login_attemps_ref">
                </v-text-field>
              </v-col>
            </v-row>
            <!--settings for password and login-->
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox class="mt-0 pt-0" v-model="config.global.isRingToneRequired" label="Do you want a ringer on incoming call">
                </v-checkbox>
                <v-select v-if="config.global.isRingToneRequired" :items="Object.keys(ringerItems)" v-model="selectedRingTone" @change="playRingTone()"
                    style="margin-top:22px;" label="Ring Tone" dense>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox v-model="config.global.idleTimeRequired" :disabled="true" style="margin-top:0px;padding-top:0px;"
                  label="Do you want to enable Idle timeout for VCIP Admin, RE Manager and RE Agent?">
                </v-checkbox>
                <v-text-field v-if="config.global.idleTimeRequired" label="Expected Idle time in minutes"
                  v-model="config.global.idleTime" :rules="rules.timeCheck" ref="variable_IdleTime">
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-top:0px;">
                <v-checkbox v-model="config.global.renameSkillLabelRequired" label="Do you want to rename existing 'skill' and 'SKILLTAG' label for RE agent?">
                </v-checkbox>
                <v-text-field v-if="config.global.renameSkillLabelRequired" label="New label for 'skill' and 'SKILLTAG'"
                  v-model="config.global.renameSkillLabel" :rules="rules.renameSkillLabelChecks" ref="rename_skill_label"
                  @blur="() => {
                    if(this.config.global.renameSkillLabel)
                      this.config.global.renameSkillLabel = this.config.global.renameSkillLabel.trim()
                  }">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-top:0px;">
                <v-checkbox v-model="config.global.enableMultiSkill" label="Do you want to enable multi skill calls for RE agents?">
                </v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-top:0px;">
                <v-checkbox v-model="config.global.hideUsernameDashboard" label="Do you want to hide username from Dashboard?">
                </v-checkbox>
              </v-col>
            </v-row>
            <!--domain configurations-->
            <div class="headline pb-1 pt-5">Domain Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field v-model="config.global.customDomain" label="Add Custom domain (https://abc.com/)"
                  :rules="rules.customDomainMatch" ref="variable_customDomain">
                </v-text-field>
                <v-text-field v-model="config.global.customREDomain" label="Add Custom domain - RE (https://abc.com/)"
                  :rules="rules.customDomainMatch" ref="variable_customREDomain">
                </v-text-field>
                <v-text-field v-model="config.global.persistHost" label="Add Custom Persist Domain (https://abc.com/)"
                  :rules="rules.customDomainMatch" ref="variable_customPersistDomain">
                </v-text-field>
                <v-text-field v-model="config.global.persistExternalHost" :rules="rules.customDomainMatch"
                  ref="variable_customExternalPersistDomain" label="Add Custom External Persist Domain (https://abc.com/)">
                </v-text-field>
                <v-text-field v-model="config.global.integratorUrl" :rules="rules.customDomainMatch"
                  ref="variable_integratorUrl" label="Add Integrator URL to Pull Skills (https://abc.com/)">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field v-model="config.global.customPath" :rules="rules.onlySpaceCheck" ref="variable_customLanding" label="Add Custom landing path (path/)">
                </v-text-field>
                <v-text-field v-model="config.global.customREPath" :rules="rules.onlySpaceCheck" ref="variable_customLandingRE" label="Add Custom landing path - RE (path/)">
                </v-text-field>
                <v-text-field v-model="config.global.virtualHost" :rules="rules.onlySpaceCheck" ref="variable_virtualHost" label="Add video service virtual host">
                </v-text-field>
              </v-col>
            </v-row>

            <!-- video conference configuration -->
            <div class="headline pb-1 mt-5">Video Conference Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <!-- COnfiguration for choosing between jitsi or chime -->
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="config.global.enableChime"
                  label="Enable Chime"
                ></v-checkbox>
                <v-text-field
                    label="Please specify the recording frame interval"
                    v-model="config.global.recordingFrameInterval"
                    :rules="rules.positiveInteger"
                    ref="variable_RecordingFrameInterval"
                    style="margin-top:0px;"
                    @blur="() => {
                      if(this.config.global.recordingFrameInterval)
                        this.config.global.recordingFrameInterval = parseInt(this.config.global.recordingFrameInterval);
                    }"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-select :items="[240,320,480,600,720]" v-model="config.global.recordingResolution" label="Select Recording Resolution">
                </v-select>
                <v-select :items="['video/webm;codecs=vp8','video/webm;codecs=vp9', 'video/webm;codecs=avc1', 'video/webm;codecs=av1']" v-model="config.global.recordingCodec" label="Select Recording Codec">
                </v-select>
              </v-col>
            </v-row>

            <!--domain configurations-->
            <!--redirection url-->
            <div class="headline pb-1 mt-5">Redirect URL Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox v-model="config.global.redirectOnLogout" label="Do you want to redirect to an external page on logout?">
                </v-checkbox>
                <v-text-field v-if="config.global.redirectOnLogout" label="Redirection URL (https://abc.com/)"
                  v-model="config.global.redirectOnLogoutURL" ref="variable_redirectOnLogoutURL" :rules="rules.redirectUrls">
                </v-text-field>
              </v-col>
            </v-row>

            <div class="headline pb-1 mt-5"> Background Change Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox v-model="config.global.enableREChangeBackground" label="Do you want RE to change custom background for video call ?">
                </v-checkbox>
              </v-col>
              <v-col v-if="config.global.enableREChangeBackground" cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  v-show = "false"
                  v-model="config.global.reBackgroundImageURL"
                  ref="variable_desiredAlias"
                ></v-text-field>
                <div style="background: #d0d0d0">
                  <v-img class="ma-4" aspect-ratio="3.4" :src="imageLogo" />
                </div>
                <v-file-input
                  id="reBackgroundFileUpload"
                  @click:clear="clearImage()"
                  show-size
                  @change="changeImage"
                  :rules="rules.fileUpload"
                  hint="Only Images (JPEG, JPG, PNG) with size < 200Kb"
                  :persistent-hint="true"
                  accept="image/png, image/jpeg, image/jpg"
                  label="Change Image"
                ></v-file-input>
              </v-col>
            </v-row>

            <!--redirection url-->
            <div class="text-right">
              <v-btn class="ma-0" type="submit" color="primary">Update</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { uploadFile, downloadFile } from "@/flux/persist.flux";
import { end_points, base_url} from "@/config";

let eventBus = window.eventBus;

export default {
  data: () => ({
    imageLogo: "",
    backgroundImageCleared: false,
    ringerItems : {
      "Ringtone 1" : "audio1.wav",
      "Ringtone 2" : "audio2.wav"
    },
    selectedRingTone : "Ringtone 1",
    rules: {
      fileUpload : [
        (value) =>
          !value ||
          value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "image/png" ||
          "Only jpeg, jpg and png files are allowed",
        (value) =>
          !value ||
          value.size < 200000 ||
          "Image size should be less than 200KB!"
      ],
        loginPasswordFieldChecks: [
            (value) => !(value == 0) || "Days entered cannot be zero",
            (value) => !/\D/.test(value) || "Can take only numeric values",
        ],
        loginAttemptsCheck: [
          (value) => !!value || "Cannot be left empty!",
          (value) => !(value == 0) || "Cannot be zero!",
          (value) => !/\D/.test(value) || "Can take only numeric values",
        ],
        onlySpaceCheck: [
          (value) => !/^\s+$/.test(value) || "Please enter valid path, cannot take only spaces"
        ],
        timeCheck : [
          (value) => !!value || "Cannot be left empty!",
          (value) => !/\D/.test(value) || "Can take only numeric values",
          (value) => !(parseInt(value) < 1 )|| "Cannot be less than a minute"
        ],
        customDomainMatch : [
          (value) => {
            if (value === "" || !value) {
              return true;
            }
            let regex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
            if (!value.match(regex)) {
              return "Enter a valid custom domain, like (https://abc.com/)!"
            }
            return true;
          }
        ],
        redirectUrls: [
          (value) => !!value || value.length || "Cannot leave this empty",
          (value) => {
              let regex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
              if (!value.match(regex)) {
                return "Enter a valid redirect URL!";
              }
              return true;
          }
        ],
        renameSkillLabelChecks: [
          (value) => !!value || "Cannot leave this empty",
          (value) => !/^\s+$/.test(value) || "Cannot take only spaces",
          (value) => !(value && value.length > 15) || "Only 15 characters are allowed",
          (value) => {
            let regex = new RegExp(/^[ a-zA-Z][a-zA-Z0-9 ]*$/);
            if (value && !value.match(regex)) {
              return "Only alphanumeric values that starts with an alphabet are allowed";
            }
            return true;
          }
        ],
        positiveInteger: [
          (value) => {
            if(value){
              if (/\D/.test(value)) {
              return "Can take only numeric values";
              }
              if (parseInt(value) < 1) {
                return "Cannot be less than 1"
              }
            }
            return true;
          }
        ],
    },
    localCopy: {},
    updatedSuccessfully: false,
    imagePersistUrl: ""
  }),
  
  methods: {
    async changeImage(file) {
      //if (this.errorOcured) {
      if (!this.backgroundImageCleared) {
        if (
          file &&
          file.size < 200000 &&
          (file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png")
        ) {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("ttl", "3 years");
          this.imagePersistUrl = await uploadFile({
            url: base_url + end_points.upload_files,
            data: formData,
            token: this.$store.getters.userData.token,
            secretKey: this.$store.getters.envValues.secretKey
          });
          if (this.imagePersistUrl) {
            let imageDataResp = await downloadFile(this.imagePersistUrl);
            this.imageLogo = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`
            this.backgroundImageCleared = false;
          }
        }
      } else {
        this.backgroundImageCleared = false;
      }
      // } else {
      //   eventBus.$emit("vueSnack", "There are some errors while uploading!");
      // }
    },

    clearImage() {
      this.backgroundImageCleared = true;
      this.imageLogo = this.config.global.reBackgroundImageURL;
    },

    validateFields(ref, typeOfValidation) {
        if (typeOfValidation === "numeric") {
            if (!ref.value || !ref.value.toString().length) {
                return true;
            }
            if (/\D/.test(ref.value)) {
                return true;
            }
            if (ref.value < 1) {
                return true
            }
            return false;
        }else{
            return false;
        }
    },
    playRingTone() {
      try {
        if(this.$notificationSound) {
          this.$notificationSound._src = require(`@/assets/${this.ringerItems[this.selectedRingTone]}`);
          this.$notificationSound.loop(false);
          this.$notificationSound.load();
          this.$notificationSound.play();
        }
      } catch(err) {
        console.log(err);
      }
    },
    validator(ref, typeOfValidation) {
      if (typeOfValidation === "customDomainMatch") {
        if (ref.value === "" || ref.value.length === 0) {
          return false;
        }
        if (!this.urlChecker(ref.value)) {
          return true;
        }
        return false;
      } else if (typeOfValidation === "checkForUrls") {
        if (!ref.value || ref.value.length < 1) {
          return true
        }
        let urls = ref.value;
        let urlList = urls.split(";");
        for (let i = 0; i < urlList.length; i++) {
          if (!this.urlChecker(urlList[i])) {
            return true;
          }
        }
        return false;
      } else if(typeOfValidation === "redirectUrl") {
        if (ref.value === "" || ref.value.length === 0) {
          return true;
        }
        if (ref.value && /^\s+$/.test(ref.value)) {
          return true;
        }
        return false;
      } else if(typeOfValidation === "onlySpaceCheck") {
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
      } else if(typeOfValidation === "labelNameCheck") {
        if (!ref.value || ref.value.length < 1) {
          return true;
        }
        if (ref.value.length > 15) {
          return true;
        }
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        let regex = new RegExp(/^[ a-zA-Z][a-zA-Z0-9 ]*$/);
        if (ref.value && !ref.value.match(regex)) {
          return true;
        }
        if(this.config.global.renameSkillLabel){
          this.config.global.renameSkillLabel = this.config.global.renameSkillLabel.trim();
        }
      } else {
        return false;
      }
    },
    urlChecker(url) {
      let regex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
      if (!url.match(regex)) {
        return false;
      }
      return true;
    },
    showAlert(message) {},
    highlighter(element) {
        // element passed is a ref
      element.focus();
      element.blur();
    },
    submitForm() {
        this.config.global.selectedRingTone = this.ringerItems[this.selectedRingTone];
        if (this.imageLogo) {
          this.config.global.reBackgroundImageURL = this.imagePersistUrl;
        }
        if ( this.config.global.isChangePasswordRequired && this.validateFields(this.$refs.password_reset_days, "numeric")) {
          this.highlighter(this.$refs.password_reset_days);
        } else if ( this.config.global.isloginAttemptLimited && this.validateFields(this.$refs.login_attemps_ref, "numeric")) {
          this.highlighter(this.$refs.login_attemps_ref);
        } else if ( this.config.global.idleTimeRequired && this.validateFields(this.$refs.variable_IdleTime, "numeric")) {
          this.highlighter(this.$refs.variable_IdleTime);
        } else if ( this.config.global.renameSkillLabelRequired && this.validator(this.$refs.rename_skill_label, "labelNameCheck")) {
          this.highlighter(this.$refs.rename_skill_label);
        } else if ( this.config.global.customDomain && this.validator(this.$refs.variable_customDomain , "customDomainMatch")) {
          this.highlighter(this.$refs.variable_customDomain);
        } else if ( this.config.global.customREDomain && this.validator(this.$refs.variable_customREDomain , "customDomainMatch")) {
          this.highlighter(this.$refs.variable_customREDomain);
        } else if ( this.config.global.persistHost && this.validator(this.$refs.variable_customPersistDomain , "customDomainMatch")) {
          this.highlighter(this.$refs.variable_customPersistDomain);
        } else if ( this.config.global.persistExternalHost && this.validator(this.$refs.variable_customExternalPersistDomain , "customDomainMatch")) {
          this.highlighter(this.$refs.variable_customREDomain);
        } else if( this.config.global.integratorUrl && this.validator(this.$refs.variable_integratorUrl , "customDomainMatch")) {
          this.highlighter(this.$refs.variable_integratorUrl);
        } else if ( this.config.global.redirectOnLogout && this.validator(this.$refs.variable_redirectOnLogoutURL , "redirectUrl")) {
          this.highlighter(this.$refs.variable_redirectOnLogoutURL);
        } else if ( this.config.global.customPath && this.validator(this.$refs.variable_customLanding , "onlySpaceCheck")) {
          this.highlighter(this.$refs.variable_customLanding);
        } else if ( this.config.global.customREPath && this.validator(this.$refs.variable_customLandingRE , "onlySpaceCheck")) {
          this.highlighter(this.$refs.variable_customLandingRE);
        } else if ( this.config.global.virtualHost && this.validator(this.$refs.variable_virtualHost , "onlySpaceCheck")) {
          this.highLighter(this.$refs.variable_virtualHost);
        } else if (this.validator(this.$refs.variable_RecordingFrameInterval , "numeric")) {
          this.highLighter(this.$refs.variable_RecordingFrameInterval);
        } else {
            eventBus.$emit("updateConfig", ["global"]);
            //Setting updatedSuccessfully as the flag sent from the event
            eventBus.$on("updatedSuccessfully", (flag)=>{
              this.updatedSuccessfully = flag;
            });
        }
    },
  },
  beforeRouteLeave (to, from, next) {
    //If not updatedSuccessfully then replace it with the localCopy that was created at the start
    if(!this.updatedSuccessfully){
      this.config.global = JSON.parse(JSON.stringify(this.localCopy.global));
    }
    next()
  },
  created() {
      if (!this.$store.getters.userData || !sessionStorage.getItem('userId')) {
      if(this.$store.getters.envValues?.client == 'citi') {
        this.$router.push({ path: '/custom/citi/errorpage', query: { errCode: 401 }});
      } else {
        this.$router.push("/");
      }
    } else {
      let userData = this.$store.getters.userData;
       if (userData.customerData) {
        this.customerId = userData.customerData.id;
       } else {
        this.customerId = userData.userId
       }
       axiosInstance.defaults.headers.common["Authorization"] = userData.token;

       if (userData.userId) {
        axiosInstance
          .get(end_points.get_customer_data(this.customerId))
          .then((customerData) => {
            if(customerData && customerData.data && customerData.data.config && customerData.data.config.global){
              Object.assign(this.config.global, customerData.data.config.global);
              this.config.global.idleTime = customerData.data.config.global.idleTime || 60; // Set 60 minutes default time to logout.
            }else{
              Object.assign(this.config.global, this.$store.getters.userData.config.global);
              this.config.global.idleTime = this.$store.getters.userData.config.global.idleTime || 60;
            }
            this.config.global.idleTimeRequired = true;

            this.localCopy.global = JSON.parse(JSON.stringify(this.config.global));   // creating a deep copy of config for local referencing

            this.selectedRingTone = this.config.global.selectedRingTone ? Object.keys(this.ringerItems).find(key => this.ringerItems[key] == this.config.global.selectedRingTone) : this.selectedRingTone;

            this.imageLogo = this.config.global.reBackgroundImageURL || ""
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  },
  watch: {},
  props: ["config"],
};
</script>
